import React from "react";
import webrtc from "webrtc-adapter";
import { QrReader } from "react-qr-reader";

console.log(webrtc.browserDetails.browser)
webrtc.browserDetails.version && console.log(webrtc.browserDetails.version)

const ReadQr = (props) => {
  const { setQrCode } = props;

  const camstyle = {
    display: "flex",
    justifyContent: "center",
  };

  const previewStyle = {
    height: 300,
    width: 200,
    display: "flex",
    justifyContent: "center",
  };

  const handleScan = (result) => {
    if (!result?.text) {
      return;
    }
    setQrCode(result?.text);
  };

  return (
    <React.Fragment>
      <div style={camstyle}>
        <QrReader
          containerStyle={previewStyle}
          scanDelay={100}
          onResult={handleScan}
          constraints={{ facingMode: "environment" }}
        />
      </div>
    </React.Fragment>
  );
};

export default ReadQr;
